import { getProjects } from "../lib/api";
import ProjectsArchive from "../components/ProjectsArchive";
import { GetStaticProps } from "next";
import { PROJECTS_PER_PAGE } from "../lib/constants";
import MetaData from "../components/MetaData3";
import Layout from "@/components/Layout";
import { getHomepageSeo } from "@/lib/seo";

export default function Index({ projects, ...props }) {

  return (
    <Layout  {...props}>
      <MetaData {...props} />
      <ProjectsArchive {...props} projects={projects} />
    </Layout>
  );
}

export const getStaticProps: GetStaticProps = async ({ params }) => {
  let getQueryParms = params ? params : null;
  const homepageSeo = await getHomepageSeo();
  const fetchedProjects = await getProjects(
    getQueryParms?.slug,
    PROJECTS_PER_PAGE
  );

  return {
    props: {
      projects: fetchedProjects?.projects ? fetchedProjects.projects : null,
      seoData: homepageSeo,
    },
    revalidate: 10,
  };
};
